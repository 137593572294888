%round-button {
    position: relative;
    display: block;
    width: 43px;
    height: 43px;
    border-radius: 60px;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    margin-left: 10px;
}

%center-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}