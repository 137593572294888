@use 'placeholders';
@use 'variables';
@use 'sass:color';
@use 'color-themes';

.entityLabel {
    //color: #7E94AC;
    color: #d2d9de;
}

/*** DATATABLE ***/
table.dataTable thead th,
table.dataTable thead td {
    border-bottom: 0px;
    //font-size: 14px;
    font-weight: normal;
    padding: 4px 2px;
    text-align: right;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
    border-top: 0px;
    padding: 11px 5px 11px 0;
}

table.dataTable.no-footer,
.dt-container.no-footer .dataTables_scrollBody {
    border-bottom: 0px;
}

.main-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    background-color: #efefef;
}

.dataTables_filter {
    display: table;
    margin-right: 10px;
    table-layout: fixed;
    text-align: right;
    border-radius: 5px;
    background-color: #f8f8fb;
    margin-bottom: 15px;
}

.dataTables_filter input {
    border: 0;
    background: none;
    outline: none !important;
    box-shadow: none;
    touch-action: manipulation;
    font-size: 1rem;
    transition: width 0.4s ease-out;
    //padding: 0.75rem 1.5rem 0.75rem 0.75rem;
    display: inline;
    color: #807da1;
}

.dataTables_filter label {
    height: 30px;
    color: #918dc1;
    text-align: left;
    padding-top: 8px;
    padding-left: 20px;
    vertical-align: middle;
}

.dataTables_filter .form-control {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIiAgIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgICB2ZXJzaW9uPSIxLjEiICAgaWQ9InN2ZzQ0ODUiICAgdmlld0JveD0iMCAwIDIxLjk5OTk5OSAyMS45OTk5OTkiICAgaGVpZ2h0PSIyMiIgICB3aWR0aD0iMjIiPiAgPGRlZnMgICAgIGlkPSJkZWZzNDQ4NyIgLz4gIDxtZXRhZGF0YSAgICAgaWQ9Im1ldGFkYXRhNDQ5MCI+ICAgIDxyZGY6UkRGPiAgICAgIDxjYzpXb3JrICAgICAgICAgcmRmOmFib3V0PSIiPiAgICAgICAgPGRjOmZvcm1hdD5pbWFnZS9zdmcreG1sPC9kYzpmb3JtYXQ+ICAgICAgICA8ZGM6dHlwZSAgICAgICAgICAgcmRmOnJlc291cmNlPSJodHRwOi8vcHVybC5vcmcvZGMvZGNtaXR5cGUvU3RpbGxJbWFnZSIgLz4gICAgICAgIDxkYzp0aXRsZT48L2RjOnRpdGxlPiAgICAgIDwvY2M6V29yaz4gICAgPC9yZGY6UkRGPiAgPC9tZXRhZGF0YT4gIDxnICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLC0xMDMwLjM2MjIpIiAgICAgaWQ9ImxheWVyMSI+ICAgIDxnICAgICAgIHN0eWxlPSJvcGFjaXR5OjAuNSIgICAgICAgaWQ9ImcxNyIgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjAuNCw4NjYuMjQxMzQpIj4gICAgICA8cGF0aCAgICAgICAgIGlkPSJwYXRoMTkiICAgICAgICAgZD0ibSAtNTAuNSwxNzkuMSBjIC0yLjcsMCAtNC45LC0yLjIgLTQuOSwtNC45IDAsLTIuNyAyLjIsLTQuOSA0LjksLTQuOSAyLjcsMCA0LjksMi4yIDQuOSw0LjkgMCwyLjcgLTIuMiw0LjkgLTQuOSw0LjkgeiBtIDAsLTguOCBjIC0yLjIsMCAtMy45LDEuNyAtMy45LDMuOSAwLDIuMiAxLjcsMy45IDMuOSwzLjkgMi4yLDAgMy45LC0xLjcgMy45LC0zLjkgMCwtMi4yIC0xLjcsLTMuOSAtMy45LC0zLjkgeiIgICAgICAgICBjbGFzcz0ic3Q0IiAvPiAgICAgIDxyZWN0ICAgICAgICAgaWQ9InJlY3QyMSIgICAgICAgICBoZWlnaHQ9IjUiICAgICAgICAgd2lkdGg9IjAuODk5OTk5OTgiICAgICAgICAgY2xhc3M9InN0NCIgICAgICAgICB0cmFuc2Zvcm09Im1hdHJpeCgwLjY5NjQsLTAuNzE3NiwwLjcxNzYsMC42OTY0LC0xNDIuMzkzOCwyMS41MDE1KSIgICAgICAgICB5PSIxNzYuNjAwMDEiICAgICAgICAgeD0iLTQ2LjIwMDAwMSIgLz4gICAgPC9nPiAgPC9nPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: 0px 3px !important;
}

.dt-container div.dt-buttons {
    float: right;
    position: relative; // otherwise you cannot click the datatables butons (like excel)
    z-index: 100;
}

.dt-container button.dt-button {
    border: 1px solid rgb(132, 151, 176) !important;
    border-radius: 5px;
    color: white;
    background-color: rgb(132, 151, 176) !important;
    background-image: none;
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
    border: 1px solid rgb(132, 151, 176) !important;
    background-color: rgb(132, 151, 176) !important;
    background-image: none;
}

// datatables pagination styling
a.paginate_button {
    display: inline-block;
    border: 1px solid rgb(132, 151, 176);
    border-radius: 5px;
    color: white;
    background-color: rgb(132, 151, 176);
    background-image: none;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;

    &.disabled {
        opacity: 40%;
        cursor: default;

        &:hover {
            color: white !important;
            background-color: rgb(132, 151, 176);
        }
    }

    &.current {
        background-color: #bf9000;
        border: 1px solid #bf9000;
    }
}

div.dataTables_paginate.paging_simple_numbers {
    padding-top: 5px;
}

table.dataTable thead .sorting {
    background: black url('../assets/sort_both.png') no-repeat center right;
    cursor: pointer;
}

table.dataTable thead .sorting_asc {
    background-image: url('../assets/sort_asc.png');
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
}

table.dataTable thead .sorting_desc {
    background-image: url('../assets/sort_desc.png');
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
}

.round-button {
    @extend %round-button;

    @include color-themes.themed('button') {
        background-color: color-themes.get-theme-color('bg-color');
        border: 1px solid color-themes.get-theme-color('border-color');
    }

    &:hover {
        @include color-themes.themed('button') {
            border-color: color-themes.get-theme-color('btn-border-hover');
            background-color: color-themes.get-theme-color('btn-bg-hover');
        }
    }
}

.round-button i {
    line-height: 0;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 24px;

    @include color-themes.themed('sticky-filter') {
        color: color-themes.get-theme-color('icon-color');
    }
}

.m-nav__link-icon > .round-button {
    border: none;
}
.m-nav__link-icon > .round-button i {
    font-size: 22px;
    color: #b1b5c1;
}

button.round-button:focus {
    outline: none;
}

/*** NAV TABS ***/
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
    color: #6f727d !important;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}

/*** title and options in dashboards ***/
.m-portlet__nav-item {
    white-space: nowrap;
}

@media screen and (max-width: 1024px) {
    .m-page .m-page__container {
        padding: 0 15px;
    }

    .m-portlet .m-portlet__body {
        padding: 2.2rem 1rem;
    }
}

/* Index dashboard style globally */
.index-dashboard {
    display: block;
    padding: 5px 10px;
    color: inherit;
    text-decoration: none;
    overflow: hidden !important;
    font-weight: initial;
    cursor: pointer;
}

.index-dashboard-hidden {
    background: #7e94ac7c !important;
}

.h-anchor-menu {
    margin-left: 20px;
    margin-top: 5px;
    width: 100%;
}

.h-anchor-menu ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    transform: skew(168deg);
}

.h-anchor-menu ul li {
    float: left;
    border-right: 1px solid #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    font-weight: bolder;
    transition: all 0.3s linear;
    margin-top: 5px;

    &:first-child {
        border-radius: 10px 0 0 10px;
    }

    &:last-child {
        border-right: none;
        border-radius: 0 10px 10px 0;
    }

    @include color-themes.themed('anchor-menu') {
        background: color-themes.get-theme-color('bg-anchor-menu');
        color: color-themes.get-theme-color('anchor-menu-color');

        &:hover {
            background: color-themes.get-theme-color('anchor-menu-bg-hover');
        }
    }

    &:hover {
        color: variables.$app-accent;
    }
}

// no scroll
.sticky-options-filter {
    border-radius: 15px;
    position: fixed;
    right: 4%;
    z-index: 99 !important;
    top: 90px;
    padding: 9px;

    @include color-themes.themed('sticky-filter') {
        box-shadow:
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
        background-color: color-themes.get-theme-color('bg-options-filter');
    }
}

.sticky-options-filter-slide {
    box-shadow: none !important;
    padding: 0px !important;
    background-color: rgba(255, 255, 255, 0) !important;
}

div.animateUp {
    .sticky-options-filter {
        top: 25px !important;
    }
}

body.m-header--minimize-off {
    .sticky-options-filter {
        transition: all 0.45s ease;
    }
}

// half scroll
body.dashboard-filter-scroll {
    .sticky-options-filter {
        top: 90px;
        padding: 9px;

        @include color-themes.themed('sticky-filter') {
            box-shadow:
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
            -webkit-box-shadow:
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
            -moz-box-shadow:
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
            background-color: color-themes.get-theme-color('bg-options-filter');
        }
    }
}

// full scroll
body.m-header--minimize-on {
    .sticky-options-filter {
        top: 60px !important;
        z-index: 102;
        -moz-transition: all 0.45s ease 0.25s !important;
        -ms-transition: all 0.45s ease 0.25s !important;
        -o-transition: all 0.45s ease 0.25s !important;
        transition: all 0.45s ease 0.25s !important;
        padding: 9px;

        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-options-filter');
        }
    }
}

.modal-alert {
    margin-top: 15rem;
}

.border-alert {
    border: red 4px solid;
}

.border-info {
    border: #bf9000 4px solid;
}

.titleAlert {
    margin-right: 13px;
    color: red;
    font-size: 21px;
}

.titlInfo {
    margin-right: 13px;
    color: #0dcaf0;
    font-size: 21px;
}

.modal-content {
    @include color-themes.themed('dx-pivot-table') {
        background-color: color-themes.get-theme-color('bg-color') !important;
        border-color: color-themes.get-theme-color('bg-color') !important;
        color: color-themes.get-theme-color('text') !important;
    }
}

.mat-form-field-appearance-outline {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        padding: 0.5em 0 1em 0 !important;
        width: 150px;
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0) !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.mat-mdc-slide-toggle {
    height: 38px !important;
}

/*FLAG*/
.mat-mdc-form-field-infix {
    min-height: 43px !important;
    height: 43px !important;
    width: 150px !important;
}

app-prod-driver-item {
    .mat-mdc-form-field-infix {
        min-height: 43px !important;
        height: 43px !important;
        width: 150px !important;
    }

    .mat-mdc-select-value-text {
        font-size: 16px;
    }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 10px 0 !important;
}

/*FLAG*/
/* this was commented and causing alignment issue for all dropdown */
.mat-mdc-form-field {
    flex-direction: inherit !important;
}
.mat-mdc-option {
    font-size: 14px !important;
}

.mat-mdc-text-field-wrapper:hover {
    cursor: pointer;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    @include color-themes.themed('table') {
        border-color: color-themes.get-theme-color('minor-group-cell');
    }

    width: 100% !important;
}

.mdc-switch:enabled .mdc-switch__track::before {
    background: #93939a !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
    background: #725a17 !important;
}

.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: #b28705 !important;
}

.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: #c0c0c0 !important;
}

::ng-deep .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: #b28705 !important;
    --mdc-switch-selected-handle-color: #b28705 !important;
    --mdc-switch-selected-hover-state-layer-color: #b28705 !important;
    --mdc-switch-selected-pressed-state-layer-color: #b28705 !important;
    --mdc-switch-selected-focus-handle-color: #725a17 !important;
    --mdc-switch-selected-hover-handle-color: #725a17 !important;
    --mdc-switch-selected-pressed-handle-color: #725a17 !important;
    --mdc-switch-selected-focus-track-color: #93939a !important;
    --mdc-switch-selected-hover-track-color: #93939a !important;
    --mdc-switch-selected-pressed-track-color: #93939a !important;
    --mdc-switch-selected-track-color: #93939a !important;
}
.mat-mdc-slide-toggle {
    height: 20px !important;
}

.mdc-icon-button.mat-mdc-icon-button.mat-warn.mat-mdc-button-base {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .mat-mdc-icon-button.mat-mdc-button-base{
    height: 30px !important;
    width: 30px !important;
    line-height: 30px !important;
} */
.mdc-button {
    letter-spacing: normal;
}

.mat-mdc-slide-toggle {
    margin-right: 10px;
}

.search-input .mat-mdc-text-field-wrapper,
.search_header .mat-mdc-text-field-wrapper,
.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-text-field-wrapper {
    flex: none !important;
}

.modal-content {
    .mat-mdc-text-field-wrapper.mdc-text-field {
        height: 56px !important;
    }
}
.event-list {
    .mat-mdc-text-field-wrapper.mdc-text-field {
        height: 56px !important;
    }
}
.event-list mat-paginator .mat-mdc-text-field-wrapper.mdc-text-field {
    height: 45px !important;
}

.bs-tooltip-bottom .tooltip-arrow {
    &::before {
        border-bottom-color: #7e94ac;
    }
}

.bs-tooltip-top .tooltip-arrow {
    &::before {
        border-top-color: #7e94ac;
    }
}

.pulse-dashboard .mat-mdc-icon-button.btntree,
.pulse-section .mat-mdc-icon-button.btntree {
    width: 28px !important;
    height: 28px !important;
}

.homepage-table tr th {
    @include color-themes.themed('table') {
        background-color: color-themes.get-theme-color('bg-header-cell') !important;
    }
}

.day {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text');
    }
}

#newsTicker {
    @include color-themes.themed('table') {
        background-color: color-themes.get-theme-color('bg-header-cell') !important;
    }
    .news button {
        @include color-themes.themed() {
            color: color-themes.get-theme-color('text');
        }
    }
}

.gold,
#kpi-table tr.rows:first-child td {
    color: #bf9000 !important;
    font-weight: bold;
}

.restrictionCell:hover {
    background-color: aqua;
}

.qr-table thead th {
    @include color-themes.themed('table') {
        background-color: color-themes.get-theme-color('bg-header-cell') !important;
    }
}

.ap-table thead th {
    @include color-themes.themed('table') {
        background-color: color-themes.get-theme-color('bg-header-cell') !important;
    }
}

.ap-rev-table thead tr th,
.ap-rev-table thead th {
    @include color-themes.themed('table') {
        background-color: color-themes.get-theme-color('bg-header-cell') !important;
    }
}
#dialog-alert-name {
    margin: 0;
}
.modal-header {
    justify-content: space-between;
}
