<!-- begin::Quick Sidebar -->
<div
    id="m_quick_sidebar"
    class="m-quick-sidebar m-quick-sidebar--tabbed m-quick-sidebar--skin-light"
    appunwraptag=""
>
    <div class="m-quick-sidebar__content">
        <span id="m_quick_sidebar_close" class="m-quick-sidebar__close">
            <i class="la la-close"></i>
        </span>
        <div class="row g-0 justify-content-left" style="padding-bottom: 5px">
            <div style="display: flex; padding: 0px 9px">
                <button
                    class="btn btn-primary btn-block btn-sidebar"
                    (click)="updateFilters()"
                >
                    Apply Current
                </button>
                <button
                    class="btn btn-secondary btn-block btn-sidebar"
                    title="Save as Default 1"
                    (click)="saveDefaultHotelList(1)"
                >
                    Save Default 1
                </button>
                <button
                    class="btn btn-secondary btn-block btn-sidebar"
                    title="Save as Default 2"
                    (click)="saveDefaultHotelList(2)"
                >
                    Save Default 2
                </button>
                <button
                    class="btn btn-secondary btn-block btn-sidebar"
                    title="Save as Default 3"
                    (click)="saveDefaultHotelList(3)"
                >
                    Save Default 3
                </button>
                <button
                    class="btn btn-secondary btn-block btn-sidebar"
                    title="Use Default 1"
                    *ngIf="shouldDisplayDefaultHotelList$ | async"
                    (click)="useDefaultHotelList(1)"
                >
                    Use Default 1
                </button>
                <button
                    class="btn btn-secondary btn-block btn-sidebar"
                    title="Use Default 2"
                    *ngIf="shouldDisplayDefaultHotelList$ | async"
                    (click)="useDefaultHotelList(2)"
                >
                    Use Default 2
                </button>
                <button
                    class="btn btn-secondary btn-block btn-sidebar"
                    title="Use Default 3"
                    *ngIf="shouldDisplayDefaultHotelList$ | async"
                    (click)="useDefaultHotelList(3)"
                >
                    Use Default 3
                </button>
                <button
                    class="btn btn-tertiary btn-block btn-sidebar"
                    title="Show a list of currently-selected hotels"
                    (click)="onShowClick()"
                >
                    Show Current Selection
                </button>
            </div>
        </div>
        <div class="filter-flex wrap">
            <div
                *ngFor="let filter of filters"
                [hidden]="filter.hidden"
                [ngClass]="filter.className"
            >
                <div class="filter-header">
                    <span>{{ filter.textHeader }}</span>
                    <span
                        *ngIf="filter.headerF"
                        (click)="filter.headerF(filter)"
                        class="ico-f {{ filter.headerIco }}"
                        placement="left"
                        title="{{ filter.tooltip }}"
                    ></span>
                </div>

                <div class="filter-content">
                    <ng-container *ngIf="filter.id === 'hotel' && company === 'rhg'">
                        <dx-list
                            #dxComponents
                            [disabled]="filter.disabled"
                            [elementAttr]="{ id: filter.id }"
                            [dataSource]="filter.listSource"
                            height="calc(33vh - 75px)"
                            [showSelectionControls]="true"
                            [selectionMode]="filter.typeList"
                            selectAllMode="allPages"
                            [searchEnabled]="filter.searchExpr !== undefined"
                            [searchExpr]="filter.searchExpr"
                            [(selectedItemKeys)]="filter.selectedKeys"
                            itemTemplate="nodes"
                            (onSelectAllValueChanged)="
                                onFilterChange($event, filter, true)
                            "
                            (onSelectionChanged)="onFilterChange($event, filter, false)"
                        >
                            <div *dxTemplate="let node of 'nodes'">
                                <a
                                    class="nav-link m-tabs__link"
                                    style="font-size: 14px"
                                    title="{{ node.id }}-{{ node.EMMA_CODE }}-{{
                                        node.name
                                    }} ({{ node.htl_id }})"
                                >
                                    <span
                                        style="
                                            display: inline-block;
                                            width: 50px;
                                            margin: 0px 0px;
                                        "
                                        >{{ node.id }}</span
                                    >-
                                    <span
                                        style="
                                            display: inline-block;
                                            width: 100px;
                                            margin: 0px 0px;
                                        "
                                        >{{ node.EMMA_CODE }}</span
                                    >-
                                    <span style="margin-left: 5px">{{ node.name }}</span>
                                    <ng-container *ngIf="!checkAllowed(node?.htl_id)"
                                        ><span
                                            id="htl-warning"
                                            class="htl-warning"
                                            title="Excluded from current dashboard"
                                        >
                                            <i class="la la-warning"></i>
                                        </span>
                                    </ng-container>
                                </a>
                            </div>
                        </dx-list>
                    </ng-container>

                    <ng-container *ngIf="filter.id === 'hotel' && company === 'lhg'">
                        <dx-list
                            #dxComponents
                            [disabled]="filter.disabled"
                            [elementAttr]="{ id: filter.id }"
                            [dataSource]="filter.listSource"
                            height="calc(33vh - 75px)"
                            [showSelectionControls]="true"
                            [selectionMode]="filter.typeList"
                            selectAllMode="allPages"
                            [searchEnabled]="filter.searchExpr !== undefined"
                            [searchExpr]="filter.searchExpr"
                            [(selectedItemKeys)]="filter.selectedKeys"
                            itemTemplate="nodes"
                            (onSelectAllValueChanged)="
                                onFilterChange($event, filter, true)
                            "
                            (onSelectionChanged)="onFilterChange($event, filter, false)"
                        >
                            <div *dxTemplate="let node of 'nodes'">
                                <a
                                    class="nav-link m-tabs__link"
                                    style="font-size: 14px"
                                    title="{{ node.id }}-{{ node.EMMA_CODE }}-{{
                                        node.name
                                    }} ({{ node.htl_id }})"
                                >
                                    <span
                                        style="
                                            display: inline-block;
                                            width: 70px;
                                            margin: 0px 0px;
                                        "
                                        >{{ node.id }}</span
                                    >-
                                    <span style="margin-left: 5px">{{ node.name }}</span>
                                    <ng-container *ngIf="!checkAllowed(node?.htl_id)"
                                        ><span
                                            id="htl-warning"
                                            class="htl-warning"
                                            title="Excluded from current dashboard"
                                        >
                                            <i class="la la-warning"></i>
                                        </span>
                                    </ng-container>
                                </a>
                            </div>
                        </dx-list>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            filter.type &&
                            filter.id !== 'hotel' &&
                            filter.id !== 'theatre' &&
                            filter.id !== 'rmc_flag' &&
                            filter.id !== 'cc'
                        "
                    >
                        <dx-list
                            #dxComponents
                            [disabled]="filter.disabled"
                            [elementAttr]="{ id: filter.id }"
                            [dataSource]="filter.listSource"
                            height="calc(33vh - 75px)"
                            [showSelectionControls]="true"
                            [selectionMode]="filter.typeList"
                            selectAllMode="allPages"
                            [searchEnabled]="filter.searchExpr !== undefined"
                            [searchExpr]="filter.searchExpr"
                            [(selectedItemKeys)]="filter.selectedKeys"
                            (onSelectAllValueChanged)="
                                onFilterChange($event, filter, true)
                            "
                            itemTemplate="nodes"
                            (onSelectionChanged)="onFilterChange($event, filter, false)"
                        >
                            <div *dxTemplate="let node of 'nodes'">
                                <a
                                    class="nav-link m-tabs__link"
                                    title="{{ node[filter.textKey] }}"
                                    >{{ node[filter.textKey] }}</a
                                >
                            </div>
                        </dx-list>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            filter.type &&
                            filter.id !== 'hotel' &&
                            filter.id === 'theatre'
                        "
                    >
                        <dx-list
                            #dxComponents
                            [disabled]="filter.disabled"
                            [elementAttr]="{ id: filter.id }"
                            [dataSource]="filter.listSource"
                            height="calc(33vh - 75px)"
                            [showSelectionControls]="true"
                            [selectionMode]="filter.typeList"
                            selectAllMode="allPages"
                            [searchEnabled]="filter.searchExpr !== undefined"
                            [searchExpr]="filter.searchExpr"
                            [(selectedItemKeys)]="filter.selectedKeys"
                            itemTemplate="nodes"
                            (onSelectAllValueChanged)="
                                onFilterChange($event, filter, true)
                            "
                            (onSelectionChanged)="onFilterChange($event, filter, false)"
                        >
                            <div *dxTemplate="let node of 'nodes'">
                                <a class="nav-link m-tabs__link">{{
                                    node[filter.textKey] == 1000
                                        ? 'EMEA'
                                        : node[filter.textKey] == 2000
                                          ? 'APAC'
                                          : node[filter.textKey]
                                }}</a>
                            </div>
                        </dx-list>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            filter.type &&
                            filter.id !== 'hotel' &&
                            filter.id === 'rmc_flag'
                        "
                    >
                        <dx-list
                            #dxComponents
                            [disabled]="filter.disabled"
                            [elementAttr]="{ id: filter.id }"
                            [dataSource]="filter.listSource"
                            height="calc(33vh - 75px)"
                            [showSelectionControls]="true"
                            [selectionMode]="filter.typeList"
                            selectAllMode="allPages"
                            [searchEnabled]="filter.searchExpr !== undefined"
                            [searchExpr]="filter.searchExpr"
                            [(selectedItemKeys)]="filter.selectedKeys"
                            itemTemplate="nodes"
                            (onSelectAllValueChanged)="
                                onFilterChange($event, filter, true)
                            "
                            (onSelectionChanged)="onFilterChange($event, filter, false)"
                        >
                            <div *dxTemplate="let node of 'nodes'">
                                <a
                                    class="nav-link m-tabs__link"
                                    title="{{ node[filter.textKey] }}"
                                    >{{ node[filter.textKey] }}</a
                                >
                            </div>
                        </dx-list>
                    </ng-container>

                    <ng-container
                        *ngIf="filter.type && filter.id !== 'hotel' && filter.id === 'cc'"
                    >
                        <dx-list
                            #dxComponents
                            [disabled]="filter.disabled"
                            [elementAttr]="{ id: filter.id }"
                            [dataSource]="filter.listSource"
                            height="calc(33vh - 75px)"
                            [showSelectionControls]="true"
                            [selectionMode]="filter.typeList"
                            selectAllMode="allPages"
                            [searchEnabled]="filter.searchExpr !== undefined"
                            [searchExpr]="filter.searchExpr"
                            [(selectedItemKeys)]="filter.selectedKeys"
                            itemTemplate="nodes"
                            (onSelectAllValueChanged)="
                                onFilterChange($event, filter, true)
                            "
                            (onSelectionChanged)="onFilterChange($event, filter, false)"
                        >
                            <div *dxTemplate="let node of 'nodes'">
                                <a
                                    class="nav-link m-tabs__link"
                                    title="{{ node[filter.textKey] }}"
                                    >{{ node[filter.textKey] }}</a
                                >
                            </div>
                        </dx-list>
                    </ng-container>

                    <ng-container *ngIf="!filter.type">
                        <dx-tree-list
                            #dxComponents
                            [disabled]="filter.disabled"
                            [elementAttr]="{ id: filter.id }"
                            [dataSource]="filter.treeSource"
                            height="calc(33vh - 75px)"
                            keyExpr="{{ filter.idKey }}"
                            parentIdExpr="parent"
                            [showRowLines]="false"
                            [showBorders]="false"
                            [columnAutoWidth]="true"
                            [showColumnHeaders]="true"
                            [(selectedRowKeys)]="filter.selectedKeys"
                            (onSelectAllValueChanged)="
                                onFilterChange($event, filter, true)
                            "
                            (onSelectionChanged)="onFilterChange($event, filter, false)"
                            [scrolling]="{ useNative: true }"
                        >
                            <dxo-selection
                                mode="multiple"
                                [recursive]="true"
                                [allowSelectAll]="true"
                            >
                            </dxo-selection>
                            <dxi-column
                                dataField="{{ filter.textKey }}"
                                [allowSorting]="false"
                                caption="Select All"
                            >
                            </dxi-column>
                        </dx-tree-list>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end::Quick Sidebar -->
<div id="hotelList" [ngClass]="showHotelList ? 'active' : 'inactive'">
    <div class="hotel-inner">
        <div class="hotel-box">
            <div
                class="hotel-box-inner hotel-box-inner clearfix"
                [ngClass]="
                    showHotelList ? 'hotel-box-inner_active' : 'hotel-box-inner_inactive'
                "
            >
                <h2>Selected Hotels</h2>
                <table
                    id="hotelListDataTable"
                    class="text-align-center swiper-no-swiping table-hover"
                    style="width: 100%"
                ></table>
                <button
                    class="btn btn-secondary float-right"
                    style="width: 100px; margin-top: 10px"
                    (click)="onHotelClose()"
                >
                    Ok
                </button>
            </div>
        </div>
    </div>
</div>
