@use 'color-themes';

#alert {
    display: none;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10001;
    .alert-box-inner {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        padding: 40px;
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
        max-height: 600px;
        overflow: auto;
        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter');
            color: color-themes.get-theme-color('text');
        }
    }
    &.active {
        display: block;
        opacity: 1;
        .alert-box-inner {
            transform: translateY(0px);
        }
    }
    .alert-inner {
        width: 100%;
        display: table;
        height: 80%;
    }
    .alert-box {
        display: table-cell;
        vertical-align: middle;

        .text-content {
            padding: 10px 0 20px;
        }
    }
}
