@use 'color-themes';
@use 'variables';
#loading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    height: 100%;
    @include color-themes.themed('loading') {
        background-color: color-themes.get-theme-color('background-color');
    }
}
