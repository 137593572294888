<!-- begin::Header -->
<header
    class="m-grid__item m-header px-0"
    data-minimize="minimize"
    data-minimize-offset="200"
    data-minimize-mobile-offset="200"
    appunwraptag=""
>
    <div class="m-header__top px-5">
        <div
            class="m-container m-container--responsive m-container--full-height m-page__container px-5"
        >
            <div class="m-stack m-stack--ver m-stack--desktop">
                <!-- begin::Brand -->
                <div class="m-stack__item m-brand">
                    <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                        <div
                            class="m-stack__item m-stack__item--middle m-brand__tools mobile-view"
                        >
                            <button
                                id="m_aside_header_menu_mobile_toggle"
                                class="m-brand__icon m-brand__toggler"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasMenu"
                                aria-controls="offcanvasMenu"
                            >
                                <span></span>
                            </button>
                            <a
                                id="m_aside_header_topbar_mobile_toggle"
                                href="javascript:;"
                                class="m-brand__icon m--visible-tablet-and-mobile-inline-block no-print"
                            >
                                <i class="flaticon-more"></i>
                            </a>
                        </div>
                        <div
                            class="m-stack__item m-stack__item--middle m-brand__logo mobile-view-logo"
                            [hidden]="!hideLHGLogo"
                        >
                            <a
                                routerlink="/dashboard/index"
                                class="m-brand__logo-wrapper"
                                href="#/dashboard/index"
                            >
                                <!-- Regular logo -->
                                <!-- <img
                                    alt="Pulse by RHG logo"
                                    src="./assets/app/media/img/logos/pulse_logo_h85-min.png"
                                /> -->
                                <!-- New Regular logo -->
                                <img
                                    alt="Pulse by RHG logo"
                                    src="./assets/app/media/img/logos/new_pulse_logo_h85.png"
                                />
                                <!-- Halloween logo -->
                                <!-- <img
                                    class="pulse-logo"
                                    alt="Pulse by RHG logo"
                                    src="./assets/app/media/img/logos/pulse_logo_halloween.png"
                                /> -->
                                <!-- Spider moving on hover -->
                                <!-- <img
                                    class="pulse-logo-hover"
                                    alt="Pulse by RHG logo"
                                    src="./assets/app/media/img/logos/pulse_logo_halloween_hover.png"
                                /> -->
                                <!-- Christmas logo -->
                                <!-- <img
                                    class="pulse-logo"
                                    alt="Pulse by RHG logo"
                                    src="./assets/app/media/img/logos/pulse_logo_christmas.gif"
                                /> -->
                                <!-- Ball moving on hover -->
                                <!-- <img
                                    class="pulse-logo-hover"
                                    alt="Pulse by RHG logo"
                                    src="./assets/app/media/img/logos/pulse_logo_christmas_hover.gif"
                                /> -->
                            </a>
                        </div>
                        <div
                            class="m-stack__item m-stack__item--middle m-brand__logo mobile-view-logo"
                            [hidden]="hideLHGLogo"
                        >
                            <a
                                routerLink="/dashboard/index"
                                class="m-brand__logo-wrapper"
                                href="#/dashboard/index"
                            >
                                <img
                                    style="margin-left: 50px"
                                    alt="Louvre Hotels logo"
                                    src="./assets/app/media/img/logos/Louvre-Hotels-Group-b.jpg"
                                    *ngIf="
                                        themeService.isDarkTheme();
                                        else lightThemeImage
                                    "
                                />
                                <ng-template #lightThemeImage>
                                    <img
                                        alt="Louvre Hotels logo"
                                        style="margin-left: 50px"
                                        src="./assets/app/media/img/logos/Louvre-Hotels-Group-w.jpg"
                                    />
                                </ng-template>
                            </a>
                        </div>
                        <!-- When REGULAR LOGO - Remove inline margin-top style  -->
                        <div
                            class="m-nav__item-container"
                            id="load_status"
                            (click)="openImportControl()"
                        >
                            <div id="status_kpi">
                                <!-- When REGULAR LOGO - Uncomment padding-top in #load_status -->
                                <span class="load_status_text">Rooms</span>
                                <mat-progress-spinner
                                    class="example-margin"
                                    [mode]="mode"
                                    [value]="value_rooms"
                                    diameter="22"
                                    [matTooltip]="rooms_tooltip"
                                    strokeWidth="5"
                                    [color]="getSpinnerColor(value_rooms)"
                                >
                                </mat-progress-spinner>
                                <span class="load_status_text" style="margin-right: 10px"
                                    >{{ value_rooms }}%</span
                                >
                            </div>

                            <div id="status_kpi" *ngIf="company == 'rhg'">
                                <span class="load_status_text">M&E</span>
                                <mat-progress-spinner
                                    class="example-margin"
                                    [mode]="mode"
                                    [value]="value_me"
                                    diameter="22"
                                    [matTooltip]="me_tooltip"
                                    strokeWidth="5"
                                    [color]="getSpinnerColor(value_me)"
                                >
                                </mat-progress-spinner>
                                <span class="load_status_text">{{ value_me }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end::Brand -->
                <!-- begin::Topbar -->
                <div
                    class="m-stack__item m-stack__item--fluid m-header-head"
                    id="m_header_nav"
                >
                    <div
                        id="m_header_topbar"
                        class="m-topbar m-stack m-stack--ver m-stack--general"
                    >
                        <div class="m-stack__item m-topbar__nav-wrapper">
                            <ul class="m-topbar__nav m-nav m-nav--inline">
                                <!--  <li class="m-nav__item">
                                    <div class="m-nav__item-container" id="load_status" (click)="openImportControl()">
                                        <span class="load_status_text">Rooms</span>
                                        <mat-progress-spinner class="example-margin" [mode]="mode" [value]="value_rooms" diameter="22" [matTooltip]="rooms_tooltip" strokeWidth="5" 
                                        [color]="getSpinnerColor(value_rooms)">
                                        </mat-progress-spinner>
                                        <span class="load_status_text" style="margin-right: 10px;">{{ value_rooms }}%</span>
                                        <span class="load_status_text">M&E</span>
                                        <mat-progress-spinner class="example-margin" [mode]="mode" [value]="value_me" diameter="22" [matTooltip]="me_tooltip" strokeWidth="5"
                                        [color]="getSpinnerColor(value_me)">
                                        </mat-progress-spinner>
                                        <span class="load_status_text">{{ value_me }}%</span>
                                    </div>
                                </li> -->
                                <li class="m-nav__item">
                                    <div
                                        class="m-nav__item-container"
                                        id="theme-switcher"
                                    >
                                        <div
                                            ngbDropdown
                                            #headerDrop="ngbDropdown"
                                            class="no-close small no-padding"
                                        >
                                            <div
                                                ngbDropdownToggle
                                                class="button no-anchor"
                                                style="cursor: pointer"
                                            >
                                                <span
                                                    class="m-topbar__welcome"
                                                    *ngIf="themeService.isDarkTheme()"
                                                >
                                                    <svg
                                                        class="no-close small"
                                                        id="icon-moon"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="24"
                                                        viewBox="0 -960 960 960"
                                                        width="24"
                                                    >
                                                        <path
                                                            d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q8 0 17 .5t23 1.5q-36 32-56 79t-20 99q0 90 63 153t153 63q52 0 99-18.5t79-51.5q1 12 1.5 19.5t.5 14.5q0 150-105 255T480-120Zm0-60q109 0 190-67.5T771-406q-25 11-53.667 16.5Q688.667-384 660-384q-114.689 0-195.345-80.655Q384-545.311 384-660q0-24 5-51.5t18-62.5q-98 27-162.5 109.5T180-480q0 125 87.5 212.5T480-180Zm-4-297Z"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    class="m-topbar__welcome"
                                                    *ngIf="!themeService.isDarkTheme()"
                                                >
                                                    <svg
                                                        class="no-close small"
                                                        id="icon-sun"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="24"
                                                        viewBox="0 -960 960 960"
                                                        width="24"
                                                    >
                                                        <path
                                                            d="M479.765-340Q538-340 579-380.765q41-40.764 41-99Q620-538 579.235-579q-40.764-41-99-41Q422-620 381-579.235q-41 40.764-41 99Q340-422 380.765-381q40.764 41 99 41Zm.235 60q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM70-450q-12.75 0-21.375-8.675Q40-467.351 40-480.175 40-493 48.625-501.5T70-510h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T170-450H70Zm720 0q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T790-510h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-450H790ZM479.825-760Q467-760 458.5-768.625T450-790v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-890v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm0 720Q467-40 458.5-48.625T450-70v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-170v100q0 12.75-8.675 21.375Q492.649-40 479.825-40ZM240-678l-57-56q-9-9-8.629-21.603.37-12.604 8.526-21.5 8.896-8.897 21.5-8.897Q217-786 226-777l56 57q8 9 8 21t-8 20.5q-8 8.5-20.5 8.5t-21.5-8Zm494 495-56-57q-8-9-8-21.375T678.5-282q8.5-9 20.5-9t21 9l57 56q9 9 8.629 21.603-.37 12.604-8.526 21.5-8.896 8.897-21.5 8.897Q743-174 734-183Zm-56-495q-9-9-9-21t9-21l56-57q9-9 21.603-8.629 12.604.37 21.5 8.526 8.897 8.896 8.897 21.5Q786-743 777-734l-57 56q-8 8-20.364 8-12.363 0-21.636-8ZM182.897-182.897q-8.897-8.896-8.897-21.5Q174-217 183-226l57-56q8.8-9 20.9-9 12.1 0 20.709 9Q291-273 291-261t-9 21l-56 57q-9 9-21.603 8.629-12.604-.37-21.5-8.526ZM480-480Z"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div
                                                ngbDropdownMenu
                                                class="dropdown-content p-0 pb-1 pt-1"
                                            >
                                                <div
                                                    [ngClass]="{
                                                        selected:
                                                            themeService.mode === 'dark'
                                                    }"
                                                    (click)="
                                                        switchTheme('dark');
                                                        headerDrop.close()
                                                    "
                                                    class="icon-moon"
                                                >
                                                    <svg
                                                        class="no-close small"
                                                        id="icon-moon"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="24"
                                                        viewBox="0 -960 960 960"
                                                        width="24"
                                                    >
                                                        <path
                                                            d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q8 0 17 .5t23 1.5q-36 32-56 79t-20 99q0 90 63 153t153 63q52 0 99-18.5t79-51.5q1 12 1.5 19.5t.5 14.5q0 150-105 255T480-120Zm0-60q109 0 190-67.5T771-406q-25 11-53.667 16.5Q688.667-384 660-384q-114.689 0-195.345-80.655Q384-545.311 384-660q0-24 5-51.5t18-62.5q-98 27-162.5 109.5T180-480q0 125 87.5 212.5T480-180Zm-4-297Z"
                                                        />
                                                    </svg>
                                                    <span>Dark</span>
                                                </div>
                                                <div
                                                    [ngClass]="{
                                                        selected:
                                                            themeService.mode === 'light'
                                                    }"
                                                    (click)="
                                                        switchTheme('light');
                                                        headerDrop.close()
                                                    "
                                                    class="icon-sun"
                                                >
                                                    <svg
                                                        class="no-close small"
                                                        id="icon-sun"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="24"
                                                        viewBox="0 -960 960 960"
                                                        width="24"
                                                    >
                                                        <path
                                                            d="M479.765-340Q538-340 579-380.765q41-40.764 41-99Q620-538 579.235-579q-40.764-41-99-41Q422-620 381-579.235q-41 40.764-41 99Q340-422 380.765-381q40.764 41 99 41Zm.235 60q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM70-450q-12.75 0-21.375-8.675Q40-467.351 40-480.175 40-493 48.625-501.5T70-510h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T170-450H70Zm720 0q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T790-510h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-450H790ZM479.825-760Q467-760 458.5-768.625T450-790v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-890v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm0 720Q467-40 458.5-48.625T450-70v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-170v100q0 12.75-8.675 21.375Q492.649-40 479.825-40ZM240-678l-57-56q-9-9-8.629-21.603.37-12.604 8.526-21.5 8.896-8.897 21.5-8.897Q217-786 226-777l56 57q8 9 8 21t-8 20.5q-8 8.5-20.5 8.5t-21.5-8Zm494 495-56-57q-8-9-8-21.375T678.5-282q8.5-9 20.5-9t21 9l57 56q9 9 8.629 21.603-.37 12.604-8.526 21.5-8.896 8.897-21.5 8.897Q743-174 734-183Zm-56-495q-9-9-9-21t9-21l56-57q9-9 21.603-8.629 12.604.37 21.5 8.526 8.897 8.896 8.897 21.5Q786-743 777-734l-57 56q-8 8-20.364 8-12.363 0-21.636-8ZM182.897-182.897q-8.897-8.896-8.897-21.5Q174-217 183-226l57-56q8.8-9 20.9-9 12.1 0 20.709 9Q291-273 291-261t-9 21l-56 57q-9 9-21.603 8.629-12.604-.37-21.5-8.526ZM480-480Z"
                                                        />
                                                    </svg>
                                                    <span>Light</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="m-nav__item">
                                    <div class="m-nav__item-container">
                                        <div
                                            ngbDropdown
                                            #headerDrop="ngbDropdown"
                                            class="no-close small no-padding"
                                        >
                                            <div
                                                ngbDropdownToggle
                                                class="button no-anchor"
                                                style="cursor: pointer"
                                            >
                                                <span class="m-topbar__welcome">
                                                    Hello,&nbsp;
                                                </span>
                                                <span class="m-topbar__username">
                                                    {{ user.first_name + ' ' }}&nbsp;
                                                </span>
                                                <span class="m-topbar__userpic">
                                                    <img
                                                        src="/users-images/{{
                                                            user.profile_image
                                                                ? user.profile_image
                                                                : 'user-128.png'
                                                        }}"
                                                        class="m--img-rounded m--marginless m--img-centered"
                                                        id="header-image"
                                                        alt="user logo"
                                                        style="
                                                            width: auto;
                                                            height: auto;
                                                            border-radius: 50%;
                                                        "
                                                    />
                                                    <i
                                                        *ngIf="updateUnread"
                                                        class="fa fa-bell alert-bell-icon"
                                                    ></i>
                                                    <span
                                                        *ngIf="info && info.badge"
                                                        class="m-badge pulse-badge"
                                                        >{{ info.badge }}</span
                                                    >
                                                </span>
                                            </div>
                                            <div
                                                ngbDropdownMenu
                                                class="dropdown-content"
                                                style="
                                                    padding: 0px;
                                                    border: 0;
                                                    max-width: 400px;
                                                    min-width: 390px;
                                                "
                                            >
                                                <span class="p-dropdown-arrow"></span>
                                                <div class="p-dropdown-header">
                                                    <div
                                                        class="m-card-user m-card-user--skin-dark"
                                                    >
                                                        <div class="m-card-user__pic">
                                                            <app-avatar></app-avatar>
                                                        </div>
                                                        <div class="m-card-user__details">
                                                            <span
                                                                class="m-card-user__name m--font-weight-500 text-white"
                                                            >
                                                                {{ user.first_name }}
                                                                {{ user.last_name }}
                                                            </span>
                                                            <span
                                                                class="m-card-user__email m--font-weight-300 m-link text-white"
                                                            >
                                                                {{ user.email }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-dropdown-body">
                                                    <div class="p-dropdown-content">
                                                        <ul
                                                            class="m-nav m-nav--skin-light"
                                                        >
                                                            <li
                                                                class="m-nav__section m--hide"
                                                            >
                                                                <span
                                                                    class="m-nav__section-text"
                                                                    zzz
                                                                >
                                                                    Section
                                                                </span>
                                                            </li>
                                                            <li
                                                                class="m-nav__item"
                                                                *ngIf="
                                                                    user.access_level >
                                                                        0 &&
                                                                    user.access_level < 3
                                                                "
                                                            >
                                                                <a
                                                                    ngbDropdownItem
                                                                    routerLink="/admin"
                                                                    class="m-nav__link"
                                                                >
                                                                    <i
                                                                        class="m-nav__link-icon flaticon-user-settings"
                                                                    ></i>
                                                                    <span
                                                                        class="m-nav__link-text"
                                                                    >
                                                                        Admin
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li
                                                                *ngIf="info"
                                                                class="m-nav__item"
                                                            >
                                                                <div
                                                                    ngbDropdownItem
                                                                    (click)="
                                                                        openCommentsDialog()
                                                                    "
                                                                    class="m-nav__link hand"
                                                                >
                                                                    <i
                                                                        class="m-nav__link-icon flaticon-chat-1"
                                                                    ></i>
                                                                    <span
                                                                        class="m-nav__link-title"
                                                                    >
                                                                        <span
                                                                            class="m-nav__link-wrap"
                                                                        >
                                                                            <span
                                                                                class="m-nav__link-text"
                                                                            >
                                                                                Messages
                                                                            </span>
                                                                            <span
                                                                                class="m-nav__link-badge"
                                                                            >
                                                                                <span
                                                                                    class="m-badge"
                                                                                    style="
                                                                                        color: white;
                                                                                        background: #bf9000;
                                                                                    "
                                                                                >
                                                                                    {{
                                                                                        info.badge
                                                                                    }}
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li
                                                                class="m-nav__separator m-nav__separator--fit"
                                                            ></li>
                                                            <li class="m-nav__item">
                                                                <a
                                                                    routerLink="/logout"
                                                                    (click)="
                                                                        headerDrop.close()
                                                                    "
                                                                    class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                                                >
                                                                    Logout
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="m-nav__item">
                                    <span
                                        class="m-nav__link m-dropdown__toggle"
                                        id="sideBarButtonToggle"
                                    >
                                        <span
                                            class="m-nav__link-icon m-nav__link-icon--aside-toggle"
                                        >
                                            <button
                                                type="button"
                                                mat-icon-button
                                                ngbTooltip="Open Hotel Filters"
                                                placement="bottom"
                                                class="round-button"
                                                (click)="openQuickSideBar()"
                                            >
                                                <i class="flaticon-menu-button"></i>
                                            </button>
                                        </span>

                                        <span
                                            (click)="openQuickSideBar()"
                                            class="m-nav__link-entity"
                                        >
                                            <ng-container *ngIf="selectedAllowedHtl > 0">
                                                <span
                                                    title="Number of hotels in your selection"
                                                >
                                                    ({{ selectedAllowedHtl }})
                                                </span>
                                            </ng-container>
                                            {{ entityService.selectedEntityLabel }}
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- end::Topbar -->
            </div>
        </div>
    </div>
</header>
<div
    class="offcanvas offcanvas-start"
    tabindex="-1"
    id="offcanvasMenu"
    data-bs-scroll="true"
    data-bs-backdrop="true"
    aria-labelledby="sidebar-menu-wrapper"
>
    <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="sidebar-menu-wrapper"></h5>
        <button
            type="button"
            class="{{
                globalService.localStorageItem('theme') == 'light'
                    ? 'btn-close text-reset'
                    : 'btn-close btn-close-white text-reset'
            }}"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        ></button>
    </div>
    <div class="offcanvas-body t-c" id="sidebar-menu">
        <div
            class="m-stack__item m-stack__item--middle m-brand__logo"
            [hidden]="!hideLHGLogo"
        >
            <a
                routerlink="/dashboard/index"
                class="m-brand__logo-wrapper"
                href="#/dashboard/index"
                onclick="$('.offcanvas').offcanvas('hide')"
            >
                <!-- Regular Logo -->
                <!-- <img
                    alt="Pulse by RHG logo"
                    src="./assets/app/media/img/logos/pulse_logo_h85-min.png"
                /> -->

                <!-- New Regular logo -->
                <img
                    alt="Pulse by RHG logo"
                    style="display: block; margin: 0 auto"
                    src="./assets/app/media/img/logos/new_pulse_logo_h85.png"
                />
                <!-- Halloween Logo -->
                <!-- <img
                    class="pulse-logo"
                    alt="Pulse by RHG logo"
                    style="height: 110px"
                    src="./assets/app/media/img/logos/pulse_logo_halloween.png"
                />
                <img
                    class="pulse-logo-hover"
                    alt="Pulse by RHG logo"
                    style="height: 110px"
                    src="./assets/app/media/img/logos/pulse_logo_halloween_hover.png"
                /> -->
                <!-- Christmas Logo -->
                <!-- <img
                    class="pulse-logo"
                    alt="Pulse by RHG logo"
                    style="height: 110px"
                    src="./assets/app/media/img/logos/pulse_logo_christmas.gif"
                />
                <img
                    class="pulse-logo-hover"
                    alt="Pulse by RHG logo"
                    style="height: 110px"
                    src="./assets/app/media/img/logos/pulse_logo_christmas_hover.gif"
                /> -->
            </a>
        </div>
        <div
            class="m-stack__item m-stack__item--middle m-brand__logo"
            [hidden]="hideLHGLogo"
        >
            <a
                routerLink="/dashboard/index"
                class="m-brand__logo-wrapper"
                href="#/dashboard/index"
                onclick="$('.offcanvas').offcanvas('hide')"
            >
                <img
                    alt="Louvre Hotels logo"
                    src="./assets/app/media/img/logos/Louvre-Hotels-Group-b.jpg"
                    *ngIf="themeService.isDarkTheme(); else lightThemeImageSide"
                />
                <ng-template #lightThemeImageSide>
                    <img
                        alt="Louvre Hotels logo"
                        src="./assets/app/media/img/logos/Louvre-Hotels-Group-w.jpg"
                    />
                </ng-template>
            </a>
        </div>
        <div class="accordion mt-4" id="mainMenu">
            <div *ngIf="hideLHGLogo">
                <a href="/products" class="home-btn light-blue-highlight">
                    Product page
                </a>
            </div>
            <div *ngIf="!hideLHGLogo">
                <a
                    routerLink="/dashboard/index"
                    class="home-btn"
                    data-bs-dismiss="offcanvas"
                    onclick="$('.collapse').collapse('hide')"
                >
                    Home
                </a>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button
                        class="accordion-button light-blue-highlight"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#my-dashboards"
                    >
                        My Dashboards
                    </button>
                </h2>
                <div
                    id="my-dashboards"
                    class="accordion-collapse collapse"
                    data-bs-parent="#mainMenu"
                >
                    <div class="accordion-body">
                        <ul class="m-menu__subnav">
                            <li
                                *ngFor="let dashboard of getPinnedDashboards()"
                                class="d-flex align-items-center gap-2"
                            >
                                <i
                                    class="fa fa-thumb-tack"
                                    [ngStyle]="{
                                        color: '#bf9000',
                                        transform: 'rotate(-45deg)',
                                        cursor: 'pointer'
                                    }"
                                    (click)="togglePin(dashboard.id)"
                                    title="Unpin"
                                ></i>

                                <a
                                    [routerLink]="['/dashboard', dashboard.id]"
                                    (click)="closeOffCanvas()"
                                    class="dashboard-link"
                                >
                                    <i
                                        class="m-menu__link-icon menu_icon flaticon-graphic-2"
                                    ></i>
                                    {{ dashboard.name }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item" *ngFor="let item of menu">
                <hr
                    *ngIf="item.name === 'user_guide'"
                    [ngStyle]="{ color: themeService.isDarkTheme() ? 'white' : 'black' }"
                />
                <h2 class="accordion-header" id="flush-headingOne">
                    <button
                        [ngClass]="{
                            'light-blue-highlight':
                                item.label == 'News' ||
                                item.label === 'User Guide' ||
                                item.label === 'Community'
                        }"
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#' + item.name"
                        aria-expanded="false"
                        aria-controls="{{ item.label }}"
                    >
                        {{ item.label }}
                        <span
                            *ngIf="item.show_badge && numberOfNews"
                            matBadge="{{ this.numberOfNews }}"
                            matBadgeColor="warn"
                            matBadgeSize="small"
                            style="top: -7px; right: -7px"
                        ></span>
                    </button>
                </h2>
                <div
                    id="{{ item.name }}"
                    class="accordion-collapse collapse"
                    aria-labelledby="{{ item.label }}"
                    data-bs-parent="#mainMenu"
                >
                    <div class="accordion-body">
                        <!-- Dashboards -->
                        <div
                            class="accordion"
                            id="{{ item.name }}-s"
                            *ngIf="item.label !== 'News'"
                        >
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#' + item.name + '-as'"
                                        aria-expanded="false"
                                        aria-controls="{{ item.name }}"
                                    >
                                        Dashboards
                                    </button>
                                </h2>
                                <div
                                    [id]="item.name + '-as'"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="{{ item.name }}"
                                    [attr.data-bs-parent]="'#' + item.name + '-s'"
                                >
                                    <div class="accordion-body">
                                        <ul class="m-menu__subnav">
                                            <li *ngFor="let child of item.children">
                                                <ng-container
                                                    *ngIf="child.type == 'Dashboards'"
                                                >
                                                    <div
                                                        class="d-flex align-items-center gap-2"
                                                    >
                                                        <i
                                                            *ngIf="
                                                                item.name !== 'user_guide'
                                                            "
                                                            class="fa fa-thumb-tack"
                                                            [ngStyle]="{
                                                                color: pinnedDashboards.includes(
                                                                    child.id
                                                                )
                                                                    ? '#bf9000'
                                                                    : '#7e94ac',
                                                                transform:
                                                                    'rotate(-45deg)',
                                                                cursor: 'pointer'
                                                            }"
                                                            (click)="togglePin(child.id)"
                                                            title="{{
                                                                pinnedDashboards.includes(
                                                                    child.id
                                                                )
                                                                    ? 'Unpin'
                                                                    : 'Pin'
                                                            }}"
                                                        ></i>

                                                        <a
                                                            [routerLink]="[
                                                                '/dashboard',
                                                                child.id
                                                            ]"
                                                            (click)="closeOffCanvas()"
                                                        >
                                                            <i
                                                                class="m-menu__link-icon menu_icon flaticon-line-graph"
                                                            ></i>
                                                            {{ child.name }}
                                                        </a>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- News -->
                        <div *ngIf="item.label === 'News'">
                            <ng-container *ngFor="let element of news; let j = index">
                                <div
                                    class="accordion"
                                    *ngIf="element.dashboard"
                                    [id]="
                                        element.dashboard !== null
                                            ? element.dashboard.split(' ').join('') + '-n'
                                            : element.dashboard
                                    "
                                >
                                    <div class="accordion-item">
                                        <h2
                                            class="accordion-header"
                                            id="flush-headingOne"
                                        >
                                            <button
                                                class="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                [attr.data-bs-target]="
                                                    '#' +
                                                    item.name +
                                                    '-' +
                                                    element.dashboard.split(' ').join('')
                                                "
                                                aria-expanded="false"
                                                aria-controls="{{ element.dashboard }}"
                                            >
                                                {{ element.dashboard }}
                                                <span
                                                    *ngFor="let item of element.children"
                                                    ><span
                                                        *ngIf="item.isNew"
                                                        style="
                                                            top: -5px;
                                                            right: -2px;
                                                            color: #bf9000;
                                                            font-weight: bold;
                                                            position: relative;
                                                            font-size: 8px;
                                                        "
                                                        >NEW</span
                                                    ></span
                                                >
                                            </button>
                                        </h2>
                                        <div
                                            [id]="
                                                item.name +
                                                '-' +
                                                element.dashboard.split(' ').join('')
                                            "
                                            class="accordion-collapse collapse"
                                            aria-labelledby="{{ element.dashboard }}"
                                            [attr.data-bs-parent]="
                                                '#' +
                                                element.dashboard.split(' ').join('') +
                                                '-n'
                                            "
                                        >
                                            <div class="accordion-body">
                                                <ul
                                                    class="m-menu__subnav"
                                                    class="news-list"
                                                >
                                                    <li
                                                        *ngFor="
                                                            let child of element.children;
                                                            let i = index
                                                        "
                                                    >
                                                        <ng-container>
                                                            <div
                                                                class="icon-container news-clickable"
                                                                data-bs-toggle="modal"
                                                                [attr.data-bs-target]="
                                                                    '#modal' + j + '-' + i
                                                                "
                                                            >
                                                                <div
                                                                    class="news-icon"
                                                                    [style.background]="
                                                                        child.color
                                                                    "
                                                                >
                                                                    <mat-icon
                                                                        style="
                                                                            color: white;
                                                                            font-size: 16px;
                                                                        "
                                                                        >{{
                                                                            child.icon
                                                                        }}</mat-icon
                                                                    >
                                                                </div>
                                                                <div
                                                                    class="icontitle-container"
                                                                >
                                                                    <span
                                                                        class="m-subheader__title"
                                                                        >{{
                                                                            child.title
                                                                        }}</span
                                                                    >
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- Presentation -->
                        <div
                            class="accordion"
                            id="{{ item.name }}-ps"
                            *ngIf="item.has_presentation && item.label !== 'News'"
                        >
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#' + item.name + '-pas'"
                                        aria-expanded="false"
                                        aria-controls="{{ item.name }}"
                                    >
                                        Presentations
                                    </button>
                                </h2>
                                <div
                                    [id]="item.name + '-pas'"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="{{ item.name }}"
                                    [attr.data-bs-parent]="'#' + item.name + '-ps'"
                                >
                                    <div class="accordion-body">
                                        <ul class="m-menu__subnav">
                                            <li *ngFor="let child of item.children">
                                                <ng-container
                                                    *ngIf="
                                                        child.type ==
                                                        'Custom Presentations'
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center gap-2"
                                                    >
                                                        <i
                                                            class="fa fa-thumb-tack"
                                                            [ngStyle]="{
                                                                color: pinnedDashboards.includes(
                                                                    child.id
                                                                )
                                                                    ? '#bf9000'
                                                                    : '#7e94ac',
                                                                transform:
                                                                    'rotate(-45deg)',
                                                                cursor: 'pointer'
                                                            }"
                                                            (click)="togglePin(child.id)"
                                                            title="{{
                                                                pinnedDashboards.includes(
                                                                    child.id
                                                                )
                                                                    ? 'Unpin'
                                                                    : 'Pin'
                                                            }}"
                                                        ></i>

                                                        <a
                                                            [routerLink]="[
                                                                '/dashboard',
                                                                child.id
                                                            ]"
                                                            (click)="closeOffCanvas()"
                                                        >
                                                            <i
                                                                class="m-menu__link-icon menu_icon flaticon-graphic-2"
                                                            ></i>
                                                            {{ child.name }}
                                                        </a>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end::Header -->
<div class="update-popup" *ngIf="updateOpen">
    <!-- <div>
        <i
            (click)="onUpdateClose()"
            class="fa fa-times"
            style="font-size: x-large; float: right; cursor: pointer"
        ></i>
    </div> -->
    <div class="row" style="justify-content: center">
        <h2>November 2021 Update</h2>
    </div>
    <hr />
    <div class="row" style="height: 500px; justify-content: center; overflow-y: auto">
        <p>
            The 2 hotel filters have been consolidated into 1. To change your hotel
            selection, click here:
            <br />
            <img
                style="width: 1000px; border: 1px solid black"
                alt=""
                src="./assets/app/media/img/update/pulseUpdateImg1-min.png"
            />
            <br /><br />
            All dashboard filters that were previously in the removed filter are now found
            in the dashboard options button here:
            <br />
            <img
                style="width: 1000px; border: 1px solid black"
                alt=""
                src="./assets/app/media/img/update/pulseUpdateImg2-min.png"
            />
            <br /><br />
            Your most recently used currency will be saved in your web browser and carried
            over to all dashboards that use currency until you log out.
        </p>
    </div>
    <div class="row" style="justify-content: center">
        <button
            class="btn btn-primary"
            (click)="onUpdateClose()"
            style="width: 150px; font-weight: bold"
        >
            Okay!
        </button>
    </div>
</div>

<!-- ModalNewsNav -->
<div *ngFor="let element of news; let j = index">
    <div
        *ngFor="let item of element.children; let i = index"
        class="modal"
        [id]="'modal' + j + '-' + i"
        tabindex="-1"
        [attr.aria-labelledby]="'modal' + j + '-' + i"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title-container-popup">
                        <div class="icon-container-popup">
                            <div class="news-icon-popup" [style.background]="item.color">
                                <mat-icon
                                    class="icon-popup"
                                    style="color: white; font-size: 16px"
                                    >{{ item.icon }}</mat-icon
                                >
                            </div>
                        </div>
                        <div class="icontitle-container-popup">
                            <span class="m-subheader__title-popup"
                                >PULSE {{ item.author }}</span
                            >
                        </div>
                    </div>
                    <div class="news-modal-title">
                        <h1 class="modal-title fs-5" [id]="'modalTitle' + j + '-' + i">
                            {{ item.title }} - {{ item.news_date }}
                        </h1>
                    </div>
                </div>
                <div class="modal-body-container" style="max-width: 700px">
                    <div
                        class="modal-body"
                        [innerHTML]="item.description"
                        style="object-fit: contain; margin: 0 auto; text-align: center"
                    ></div>
                    <div
                        *ngIf="item.screenshot"
                        class="modal-picture"
                        style="margin: 0 auto; margin-bottom: 10px; text-align: center"
                    >
                        <img
                            [src]="'/screenshots/' + item.screenshot"
                            style="
                                border-radius: 10px;
                                object-fit: contain;
                                max-width: 100%;
                            "
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
