@use 'color-themes';

#m_quick_sidebar {
    .dx-list-next-button > .dx-button > .dx-button-content {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .filter-column-medium {
        max-width: 20%;
    }

    .filter-column-25pct {
        max-width: 25%;
    }

    .filter-column-30pct {
        max-width: 30%;
    }

    .filter-column-medium-fixed {
        min-width: 20%;
        max-width: 145px;
    }

    .filter-column-medium-fixed-165 {
        max-width: 165px;
    }

    .filter-column-200-fixed {
        max-width: 200px;
    }

    .filter-column-190-fixed {
        max-width: 190px;
    }

    .filter-column-small-125 {
        max-width: 125px;
    }

    .filter-column-small {
        max-width: 115px;
    }

    .filter-column-xsmall {
        max-width: 100px;
    }

    .filter-column-large {
        width: 30%;
    }

    .dx-list-item {
        border: none;
    }

    .filter-header {
        margin: 4px;
        text-align: center;

        span {
            font-size: large;
        }

        @include color-themes.themed() {
            color: color-themes.get-theme-color('text');
        }
    }

    .filter-flex {
        padding: 0;
        margin: 0;
        list-style: none;
        -ms-box-orient: horizontal;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
    }

    .wrap {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .filter-content {
        margin: 4px;
        padding: 6px;
        border-radius: 5px;

        .dx-list-item-content {
            padding: 0px;

            .nav-link {
                padding: 4px;
            }
        }

        .dx-list-select-all-label {
            padding: 0 8px;

            @include color-themes.themed('entity-filter') {
                color: color-themes.get-theme-color('text-color') !important;
            }
        }

        @include color-themes.themed('entity-filter') {
            background-color: color-themes.get-theme-color('entity-filter-bg');
            border: color-themes.get-theme-color('border-color') solid 1px;
        }

        .dx-item a {
            @include color-themes.themed('entity-filter') {
                color: color-themes.get-theme-color('text-color') !important;
            }
        }

        input {
            @include color-themes.themed() {
                color: color-themes.get-theme-color('text') !important;
            }
        }
    }

    .filters-footer {
        margin-right: 25px;
    }

    .filters-footer .buttons {
        float: right;
    }

    .filters-footer button {
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .dx-treelist-container {
        background-color: transparent;

        .dx-header-row .dx-treelist-text-content {
            color: #333;
        }

        .dx-treelist-cell-expandable {
            background-color: lightgray;
        }

        // custom native scrollbar because of devextreme 19.2 treelist scroll bug
        ::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        ::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }

    .ico-f {
        margin-left: 5px;
        cursor: pointer;
    }

    .ico-f:hover {
        color: lightgray;
    }

    .btn-sidebar {
        margin-top: 0;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }

    .btn-tertiary {
        background-color: hsl(211, 22%, 58%);
        color: white;

        &:hover {
            background-color: hsl(211, 22%, 48%);
        }
    }

    .btn-block {
        width: 100%;
    }

    .responsive-div {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .line1,
    .line2 {
        flex: 40%;
    }

    .line3 {
        flex: 20%;
    }
}

.htl-warning {
    margin-left: 5px;
    color: red;
}

#hotelList {
    display: none;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10001;

    .hotel-box-inner {
        transform: translateY(100px);
        padding: 40px;
        max-width: 350px;
        width: 100%;
        background-color: #fff;
        margin: 0 auto;
        max-height: 840px;
        overflow: auto;
    }

    &.active {
        display: block;
        opacity: 1;

        .hotel-box-inner {
            transform: translateY(0px);
        }
    }

    .hotel-inner {
        width: 100%;
        display: table;
        height: 80%;
    }

    .hotel-box {
        display: table-cell;
        vertical-align: middle;

        .text-content {
            padding: 10px 0 20px;
        }
    }

    table.dataTable thead th,
    table.dataTable thead td {
        text-align: left;
    }
}
