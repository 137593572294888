import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { User } from '@sentry/browser';
import { CommentsDialogComponent } from 'app/core/components/popup/comments-dialog/comments-dialog.component';
import { PopupService } from 'app/core/components/popup/popup.service';
import { ServerService } from 'app/core/services/server.service';
import { DashboardAccess } from 'app/shared/interfaces/UserInterface';
import { abortUnsubscribe, getDateTime } from 'app/shared/utils/shared-methods.utils';
import { Subject, Subscription } from 'rxjs';
import { EntityService } from '../../core/services/entity.service';
import { UserService } from '../../core/services/user.service';
import { saveAs } from 'file-saver';
import { EventManagerService } from 'app/shared/utils/event-manager.service';
import { DOCUMENT, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ThemeService } from 'app/core/services/theme.service';
import { environment } from 'environments/environment';
import { GlobalService } from 'app/core/services/global.service';
import {
    MatProgressSpinner,
    ProgressSpinnerMode
} from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { AvatarComponent } from '../../core/components/avatar/avatar.component';
import {
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbTooltip
} from '@ng-bootstrap/ng-bootstrap';
import { MatTooltip } from '@angular/material/tooltip';
import { UnwrapTagDirective } from '../directives/unwrap-tag.directive';
import { DashboardService } from 'app/core/services/dashboard.service';
declare let mLayout: any;
@Component({
    selector: 'dashboard-header-nav',
    templateUrl: './dashboard-header-nav.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./dashboard-header-nav.component.scss'],
    imports: [
        UnwrapTagDirective,
        RouterLink,
        NgIf,
        MatProgressSpinner,
        MatTooltip,
        NgbDropdown,
        NgbDropdownToggle,
        NgbDropdownMenu,
        NgClass,
        AvatarComponent,
        NgbDropdownItem,
        MatIconButton,
        NgbTooltip,
        NgFor,
        NgStyle,
        MatBadge,
        MatIcon
    ]
})
export class DashboardHeaderNavComponent implements OnInit, OnDestroy {
    public news;
    public formattedToday;
    public numberOfNews;
    public todayNews;
    public menu: any;
    public user: User;
    public info: any; // { badge: number, error?: any }
    public downloadBarDisabled = false;
    public updateUnread = 0;
    public updateOpen = false;
    public allowedHtlIds: number[] = [];
    protected subscriptions: Subscription[] = [];
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private timer: any;
    public selectedAllowedHtl = 0;
    public hideLHGLogo = environment.company === 'lhg' ? false : true;
    public company = environment.company;
    public pinnedDashboards: any[] = [];

    // Progress spinners
    private lastLoadStatusTime: Date | null = null;
    private loadStatusInterval: any;
    mode: ProgressSpinnerMode = 'determinate';
    value_rooms = 0;
    value_me = 0;
    rooms_tooltip = 'Rooms load status for your current hotel selection: 0%';
    me_tooltip = 'ME load status for your current hotel selection : 0%';
    selectedDashboardId: string | null = null;

    constructor(
        public entityService: EntityService,
        private userService: UserService,
        private serverService: ServerService,
        private popupService: PopupService,
        private router: Router,
        private eventManagerService: EventManagerService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        public themeService: ThemeService,
        public globalService: GlobalService,
        private dashboardService: DashboardService
    ) {
        this.ngUnsubscribe = new Subject<void>();
    }
    public ngOnInit(): void {
        this.router.events.subscribe(() => {
            const bodyNode = this.document.getElementsByTagName('body')[0];
            if (this.themeService.mode === 'dark') {
                this.renderer.removeClass(bodyNode, 'theme--default');
                this.renderer.addClass(bodyNode, 'theme--dark');
            } else {
                this.renderer.removeClass(bodyNode, 'theme--dark');
                this.renderer.addClass(bodyNode, 'theme--default');
            }
        });
        this.getNewDevelopments();
        this.getLoadStatus();
        this._registerChanges();
        this.getPinnedDashboards();
        this.pinnedDashboards = (this.userService.user.pinned || []).map(
            (p) => p.dashboard_id
        );

        this.eventManagerService.subscribe('dashboardSwitch', (response) => {
            this.allowedHtlIds = response.data;
            this.selectedAllowedHtl = this.entityService.getSelectedHtlIdsAllowedLength(
                this.allowedHtlIds
            );
        });
        this.eventManagerService.subscribe('selectedEntityUpdate', (_response) => {
            this.selectedAllowedHtl = this.entityService.getSelectedHtlIdsAllowedLength(
                this.allowedHtlIds
            );
            this.getLoadStatus();
        });

        // Initialize lastLoadStatusTime
        this.lastLoadStatusTime = new Date();

        // Set up interval to check every minute
        this.loadStatusInterval = setInterval(() => {
            const now = new Date();
            if (this.lastLoadStatusTime) {
                const diff = now.getTime() - this.lastLoadStatusTime.getTime();
                const diffMinutes = diff / (1000 * 60);
                if (diffMinutes >= 5) {
                    this.getLoadStatus();
                }
            } else {
                this.getLoadStatus();
            }
        }, 60000); // 60000 ms = 1 minute
    }

    public checkForUpdates(): void {
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = monthNames[today.getMonth()];
        const year = today.getFullYear().toString();
        this.formattedToday = day + ' ' + month + ' ' + year;
    }

    public getNumberOfNewAlerts(data): void {
        this.numberOfNews = 0;
        for (const element of data) {
            if (element.news_date === this.formattedToday) {
                this.numberOfNews += 1;
            }
        }
    }

    public getIcon(data): void {
        for (const element of data) {
            element.icon = 'fiber_new';
            element.color = 'rgb(38, 128, 189)';
        }
    }

    public getLoadStatus(): void {
        this.serverService.post(
            'dashboard/import-control/getRoomsMeStatus',
            { htl_id: this.entityService.selectedHtlId },
            (data: { metric: string; load_percentage: number }[]) => {
                data.forEach((item) => {
                    if (item.metric === 'Rooms') {
                        this.value_rooms = item.load_percentage;
                        this.rooms_tooltip =
                            'Rooms load status for your current hotel selection: ' +
                            item.load_percentage +
                            '%';
                    } else if (item.metric === 'M&E') {
                        this.value_me = item.load_percentage;
                        this.me_tooltip =
                            'ME load status for your current hotel selection : ' +
                            item.load_percentage +
                            '%';
                    }
                });
            },
            (err) => {
                console.error(err);
            },
            this.ngUnsubscribe,
            true
        );
        // Update lastLoadStatusTime
        this.lastLoadStatusTime = new Date();
    }

    public getNewDevelopments(): void {
        this.serverService.get(
            'dashboard/summary' + '/getNewDevelopments',
            {},
            (data: any) => {
                this.checkForUpdates();
                this.getNumberOfNewAlerts(data);
                this.getIcon(data);
                data.map((element) =>
                    element.news_date === this.formattedToday
                        ? (element.isNew = true)
                        : (element.isNew = false)
                );
                const transformedData = data.reduce((acc, curr) => {
                    const existingDashboard = acc.find(
                        (item) => item.dashboard === curr.dashboard
                    );
                    if (existingDashboard) {
                        existingDashboard.children.push(curr);
                    } else {
                        acc.push({ dashboard: curr.dashboard, children: [curr] });
                    }

                    return acc;
                }, []);

                this.news = transformedData;
            },
            (err) => {
                console.error(err);
            },
            this.ngUnsubscribe,
            true
        );
    }

    public init(dashboards: any[]): void {
        this.user = this.userService.user;
        if (dashboards == null) return;
        const menus: Array<DashboardCategory> = [];
        let menu_name = '';
        let cpt = -1;
        for (let i = 0; i < dashboards.length; i++) {
            const d = dashboards[i];
            if (menu_name != d['menu_name']) {
                menu_name = d['menu_name'];
                cpt++;
                menus[cpt] = {
                    label: menu_name,
                    name: d['menu_id'],
                    has_presentation: false,
                    show_badge: false,
                    children: []
                };
            }
            if (d.type === 'Custom Presentations') menus[cpt].has_presentation = true;
            // For News item (first position of the array), we switch show_badge to true.
            menus[0].show_badge = true;
            menus[cpt].children.push(d);
        }
        this.menu = menus;
        setTimeout(() => {
            mLayout.initHeader();
            $('a[data-bs-toggle=collapse]').on('click', function () {
                // swap chevron
                $(this).children('i').toggleClass('fa-chevron-down fa-chevron-right');
            });
            $('a.list-group-item.m-menu__link').on('click', function () {
                // close menu
                $(this)
                    .closest('li.m-menu__item--open-dropdown.m-menu__item--hover')
                    .removeClass('m-menu__item--open-dropdown m-menu__item--hover');
            });
        }, 500);
        if (this.userService.user) {
            this.serverService.get(
                'access/getUpdateRead',
                {
                    user: this.userService.user.user_id,
                    update: 'updateRead1'
                },
                (data: { CT: number }[]) => {
                    if (!data[0].CT) this.updateUnread = 1;
                }
            );
        }
    }
    public openQuickSideBar(): void {
        $('#m_quick_sidebar_toggle').trigger('click');
    }
    public openCommentsDialog(): void {
        if (this.info.error) {
            // Do something?
        } else {
            this.popupService.open(
                CommentsDialogComponent as Component,
                undefined,
                (node) => {
                    // Go to dashboard
                    this.router.navigateByUrl(
                        '/dashboard/' + node.dashboard_id + '#' + node.section_id
                    );
                },
                () => {
                    this._getInfo();
                },
                'xl'
            );
        }
    }
    public downloadBar(): void {
        this.downloadBarDisabled = true;
        abortUnsubscribe(this.ngUnsubscribe);
        this.serverService.get(
            'admin/bar-extract/getBar',
            {},
            (data: any) => {
                const replacer = (_key, value): string => (value === null ? '' : value);
                const header = Object.keys(data[0]);
                const csv = data.map((row) =>
                    header
                        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                        .join(',')
                );
                csv.unshift(header.join(','));
                const csvArray = csv.join('\r\n');
                const blob = new Blob([csvArray], { type: 'text/csv' });
                saveAs(blob, 'BAR_Extract_' + getDateTime() + '.csv');
            },
            (error) => {
                console.error(error);
            },
            this.ngUnsubscribe,
            true
        );
        this.downloadBarDisabled = false;
    }
    private _registerChanges(): void {
        this.subscriptions.push(
            this.userService.on_ready.subscribe((ready) => {
                if (ready) {
                    //console.log('this.userService.user.dashboards',this.userService.user.dashboards)
                    this.init(this.userService.user.dashboards);
                }
            })
        );
    }
    private _getInfo(): void {
        abortUnsubscribe(this.ngUnsubscribe);
        this.serverService.post(
            'reader-comments-tool/info',
            {},
            (data: any) => {
                this.info = data;
            },
            (error) => {
                console.error(error);
                this.info = { badge: 0, error: error };
            },
            this.ngUnsubscribe,
            true
        );
    }
    public onUpdateOpen(): void {
        this.updateOpen = true;
    }
    public onUpdateClose(): void {
        this.updateOpen = false;
        if (this.userService.user && this.updateUnread) {
            this.serverService.post(
                'access/setUpdateRead',
                {
                    user: this.userService.user.user_id,
                    update: 'updateRead1'
                },
                () => {
                    // returned data not needed
                }
            );
        }
        this.updateUnread = 0;
    }
    public ngOnDestroy(): void {
        abortUnsubscribe(this.ngUnsubscribe, true);
        if (this.timer) {
            clearInterval(this.timer);
        }
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
        if (this.loadStatusInterval) {
            clearInterval(this.loadStatusInterval);
        }
    }
    public getNumberOfSelectedHotels(): string {
        const _selectedHotels = this.entityService?.selectedHtlId.filter((htl) =>
            this.allowedHtlIds.includes(htl)
        );
        return _selectedHotels.length.toString();
    }

    public switchTheme(theme: string): void {
        this.themeService.themeChanged$.next(theme);
    }
    public openImportControl(): void {
        this.router.navigate(['dashboard/import-control']);
    }
    public getSpinnerColor(value: number): string {
        if (value < 50) {
            return 'warn';
        }
        if (value < 80) {
            return 'accent';
        }
        return 'primary';
    }

    togglePin(dashboardId: number): void {
        const isPinned = this.pinnedDashboards.includes(dashboardId);
        const userId = this.userService.user.user_id;

        const action$ = isPinned
            ? this.dashboardService.unpinDashboard(userId, dashboardId)
            : this.dashboardService.pinDashboard(userId, dashboardId);

        action$.subscribe({
            next: () => {
                if (isPinned) {
                    this.pinnedDashboards = this.pinnedDashboards.filter(
                        (id) => id !== dashboardId
                    );
                } else {
                    this.pinnedDashboards.push(dashboardId);
                }
            },
            error: (err) => console.error('Error toggling pin:', err)
        });
    }

    getPinnedDashboards() {
        const allDashboards = this.menu.flatMap((item) => item.children || []);
        return allDashboards.filter((d) => this.pinnedDashboards.includes(d.id));
    }

    closeOffCanvas() {
        ($('.offcanvas') as any).offcanvas('hide');
    }
}

export interface DashboardCategory {
    label: string;
    name: string;
    has_presentation: boolean;
    show_badge: boolean;
    children: Array<DashboardAccess>;
}
